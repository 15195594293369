import React from "react"

class AboutShort extends React.Component {

    constructor(props){
        super(props);
    }
/*
<p>
                <strong>Is it time to</strong> learn the secrets of global interconnection, of staying in touch, of travelling, and of connecting to people across distance and culture?
                </p>
                <p> Managing our time and interactions with other people throughout the day is difficult enough,
                    but what about all the problems that arise when you start involving people in a different city, country, and timezone?
                    How do we communicate, coordinate, and congregate with people from all around the world who each have their own lives and priorities?
                </p>
                <p>
                    <em>Is it time to</em> is here to help anyone that works across different parts of the globe at the same time.</p>
                <p>
                    Expats, frequent flyers, employees of multinational corporations, if you have someone important to you 
                    in another part of the world, we're here to offer tips, hints, strategies, and tools to help you communicate.
                </p>
*/
    render() {
        return (
            <div>
                <p>
                    Is it time to find a stable job, to sell 30 years of your life to a bank in exchange for a small chunk of suburbia, to live
                    in comfort having never seen the world?
                </p>
                    <h3><small>Or...</small></h3>
                <p>
                    Is it time to travel, to see the world, to experience life before you're too old to leave the house?
                </p>
                <p>    
                    We are here...
                    <ul>
                        <li>To help you maximise your travel,</li>
                        <li>To help you stay in touch around the world, and</li>
                        <li>To help you learn about how the world is big and complicated place to explore.</li>
                    </ul>
                </p>
                
            </div>
        )
    }
}

export default AboutShort